import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import DataTable from 'mui-datatables'
import {getAllProviders} from "../../../services/api/provider.service";
import SearchInput from "../../parts/SearchInput";
import {feedDetailsPath, providerListPath, sellOnPromPath, productsPath, providerDetailsPath} from "../../../config/routes";
import { useHistory } from "react-router-dom";
import { textLabels } from 'config/tableConfig/textLabels'
import { getAllFeeds } from "../../../services/api/feed.service";
import {apiBaseURL} from "../../../config/constants";
import { Card, CardContent, Typography, Grid, Accordion, AccordionSummary, AccordionDetails, IconButton, List, ListItem, ListItemIcon } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

const imagesUrl = apiBaseURL.slice(0, -3) + 'images'

const FeedList = () => {

    const [feeds, setFeeds] = useState([]);
    const [search, setSearch] = useState(null);


    useEffect(() => {
        getAllFeeds().then((data) => {
            setFeeds(data);
        })
    }, []);

    const history = useHistory()

    const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
        alert("ХМЛ-выгрузка скопирована! Теперь можете использовать ее для импорта товаров на вашем сайте"); 
    }).catch(() => {
        alert("Ошибка при копировании");
    });
};

    const columns = [
        {name: 'id', label: 'Номер'},
        {name: 'name', label: 'Название ХМЛ'},
        { name: 'updated_at', label: 'Дата обновления', options: { customBodyRender: value => new Date(value).toLocaleDateString('ru-RU') } },
        { 
        name: 'xmlLink', 
        label: 'XML-выгрузка', 
        options: { 
            customBodyRender: (value, tableMeta) => {
                const feedId = tableMeta.rowData[0];
                const xmlLink = `${apiBaseURL}/feeds/${feedId}.xml`;
                return (
                    // <div style={{ display: "flex", alignItems: "center" }}>
                    //     <span 
                    //         onClick={() => copyToClipboard(xmlLink)}
                    //         style={{ 
                    //             color: "#DB4C19", 
                    //             textDecoration: "none", 
                    //             cursor: "pointer" 
                    //         }}
                    //     >
                    //         {xmlLink}
                    //     </span>
                    //     <Tooltip title="Скопировать ссылку">
                    //         <IconButton 
                    //             onClick={() => copyToClipboard(xmlLink)} 
                    //             size="small" 
                    //             style={{ marginLeft: 6 }}
                    //         >
                    //             <FileCopyOutlinedIcon fontSize="small" />
                    //         </IconButton>
                    //     </Tooltip>
                    // </div>
                    <div style={{ display: "flex", alignItems: "center", gap: 6, flexWrap: "wrap" }}>
    <Typography 
        variant="body1" 
        style={{ color: "#2792E0", textDecoration: "underline", wordBreak: "break-word" }}
    >
        {xmlLink}
    </Typography>

    <Tooltip title="Скопировать ссылку">
        <IconButton 
            onClick={() => copyToClipboard(xmlLink)} 
            size="small" 
            aria-label="Скопировать XML ссылку"
        >
            <FileCopyOutlinedIcon fontSize="small" />
        </IconButton>
    </Tooltip>
</div>
                );
            } 
        } 
    }
    ]

    const options = {
        download: false,
        print: false,
        search: false,
        filter: false,
        viewColumns: false,
        selectableRowsHeader: false,
        selectableRows: 'none',
        pagination: false,
        textLabels,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 50, 100],
        onRowClick: (row) => {
            history.push(feedDetailsPath.replace(':id', row[0]))
        },
    }

    const onSearch = (searchQuery) => {
        setSearch(feeds.filter(feed => {
            var regExp = new RegExp(searchQuery, 'i')
            return feed.name.search(regExp) !== -1
                || feed.updated_at.search(regExp) !== -1;
        }))
    }

    return (
        <Paper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
            <Card fullWidth>
                <CardContent>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            color='textPrimary'
                            variant='caption'
                            paragraph
                            style={{ fontSize: 24, textTransform: 'uppercase' }}
                        >
                            {"Мои XML выгрузки"}
                        </Typography>
                        <Typography
                            color='textSecondary'
                            variant='body2'
                            paragraph
                            style={{ fontSize: 14 }}
                        >
                            XML-выгрузки - это ваши товары, которые вы выберете для загрузки на ваш сайт.
                        </Typography>
                        <Typography
                            color='textSecondary'
                            variant='body2'
                            paragraph
                            style={{ fontSize: 14 }}
                        >
                            Эти файлы будут содержать всю информацию о товарах, необходимую для импорта на сайты и маркетплейсы (Prom, Bigl, Розетка, Алло, Opencart, Wordpress и т.д.) и для обновления цен и наличия.
                        </Typography>
                        <Typography
                            color='textSecondary'
                            variant='body2'
                            paragraph
                            style={{ fontSize: 14 }}
                        >
                                    {"ВАЖНО! ХМЛ-выгрузки скачивать или открывать в браузере не нужно! Они используются только для импорта товаров на торговые площадки ("}
                            <Link to={sellOnPromPath} style={{ color: '#2792E0', textDecoration: 'underline' }}>
                                например, на сайт на Проме
                            </Link>
                            {")."}
                        </Typography>
                    </div>
                </CardContent>
                    </Card>
            <DataTable
                data={search ? search : feeds}
                options={options}
                columns={columns}
                // title={<SearchInput tooltipsOpened={false} onSearch={onSearch} position='start' placeholder={'Поиск'}/>}
                    />
                </Grid>
                <Grid item xs={12}>
            <Card fullWidth>
                <CardContent>
                <Typography
                    variant='h6'
                    paragraph
                    align='center'
                    style={{ fontSize:  16, paddingTop: 20 }}
                >
                    Инструкция по созданию ХМЛ-выгрузки
                </Typography>
                <Typography
                    variant='h6'
                    paragraph
                    // align='center'
                    style={{ fontSize:  14 }}
                >
                    Как сделать свою ХМЛ-выгрузку для импорта товаров с платформы на ваш сайт?
                </Typography>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ color: '#DB4C19', fontWeight: 500 }}>
                            1. По категориям товаров
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <Typography variant="body2" fontSize="14px">
                                    В общем каталоге выберите нужные категории товаров и добавьте их в выгрузку:
                                </Typography>                                    
                                </ListItem>
                                <img
                                src={`${imagesUrl}/category-import-1.jpg`}
                                alt="category-import-1.jpg"
                                style={{ display: 'block', width: '80%', margin: '16px auto' }}
                                />
                                <img
                                src={`${imagesUrl}/category-import-2.jpg`}
                                alt="category-import-2.jpg"
                                style={{ display: 'block', width: '80%', margin: '16px auto' }}
                                />
                            <ListItem>
                                <Typography variant="body2" fontSize="14px">
                                    Вверху страницы будет отображаться количество товаров в выгрузке:
                                    </Typography>                                    
                                </ListItem>
                                <img
                                src={`${imagesUrl}/category-import-3.jpg`}
                                alt="category-import-3.jpg"
                                style={{ display: 'block', width: '80%', margin: '16px auto' }}
                                />
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ color: '#DB4C19', fontWeight: 500 }}>
                            2. По поставщикам
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <Typography variant="body2" fontSize="14px">
                                        {"В разделе "}
                            <Link to={providerListPath} style={{ color: '#2792E0', textDecoration: 'underline' }}>
                                Поставщики
                            </Link>
                            {" выбрать поставщика и добавить все его товары в выгрузку:"}
                                    </Typography>
                                </ListItem>
                                <img
                                src={`${imagesUrl}/supplier-import-1.jpg`}
                                alt="supplier-import-1.jpg"
                                style={{ display: 'block', width: '80%', margin: '16px auto' }}
                                />
                            <ListItem>
                                <Typography variant="body2" fontSize="14px">
                                    Вверху страницы будет отображаться количество товаров в выгрузке:
                                    </Typography>                                    
                                </ListItem>
                                <img
                                src={`${imagesUrl}/supplier-import-2.jpg`}
                                alt="supplier-import-2.jpg"
                                style={{ display: 'block', width: '80%', margin: '16px auto' }}
                                />
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="body1" style={{ color: '#DB4C19', fontWeight: 500 }}>
                            3. По отфильтрованным товарам в общем каталоге
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <Typography variant="body2" fontSize="14px">
                                    В общем каталоге используйте фильтры по бренду, ценам и добавляйте отфильтрованные товары в выгрузку:
                                </Typography>
                                </ListItem>
                                <img
                                src={`${imagesUrl}/filter-import-1.jpg`}
                                alt="filter-import-1.jpg"
                                style={{ display: 'block', width: '80%', margin: '16px auto' }}
                                />
                            <ListItem>
                                <Typography variant="body2" fontSize="14px">
                                    Вверху страницы будет отображаться количество товаров в выгрузке:
                                    </Typography>                                    
                                </ListItem>
                                <img
                                src={`${imagesUrl}/filter-import-2.jpg`}
                                alt="filter-import-2.jpg"
                                style={{ display: 'block', width: '80%', margin: '16px auto' }}
                                />
                        </List>
                    </AccordionDetails>
                </Accordion>
                <Typography
                    variant='h6'
                    paragraph
                    style={{ fontSize:  14, paddingTop: 20 }}
                >
                        Когда сформируете выгрузку с нужным количеством товаров - нажмите "Выгрузить товары". Создайте название вашей ХМЛ-выгрузки.
                </Typography>
                {/* <Typography
                    variant='h6'
                    paragraph
                    style={{ fontSize:  14 }}
                >
                        Теперь выгрузка будет отображаться в списке ваших ХМЛ-выгрузок и ее можно использовать для загрузки товаров, например, в ваш магазин на Проме.
                </Typography> */}
                {/* <Typography
                    variant='h6'
                    paragraph
                    style={{ fontSize:  14 }}
                >
                        <Link to="/question-answer#import-section" style={{ color: '#2792E0', textDecoration: 'none' }}>Инструкция по использованию ХМЛ-выгрузки при загрузке товаров на Пром</Link>
                            </Typography> */}
                <Typography
                    variant='h6'
                    paragraph
                    style={{ fontSize:  14 }}
                >
                        Теперь выгрузка будет отображаться в списке ваших ХМЛ-выгрузок и ее можно использовать для загрузки товаров в ваш магазин.
                </Typography>
                <Typography
                    variant='h6'
                    paragraph
                    style={{ fontSize:  14 }}>
                            <Link to="/question-answer#import-section" style={{ color: '#2792E0', textDecoration: 'underline' }}>
                                Здесь пошаговая инструкция 
                            </Link>
                            {" как загрузить товары с платформы на Пром."}
                    </Typography>
                </CardContent>
                    </Card>
                </Grid>
                </Grid>
        </Paper>
        
    )
}

export default FeedList
