import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'


import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Table from './Table'
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {Box, CardMedia, FormControl, MenuItem, Select} from "@material-ui/core";
import {recovery} from "../../../services/api/login.service";
import {orderDetailsPath, recoveryPasswordSuccessPath} from "../../../config/routes";
import {sendOrderEmail, updateOrder} from "../../../services/api/order.service";
import {showSnack} from "../../../storage/actions/snack.actions";
import _ from "lodash";
import {onlyAdminOrGreater, onlySupplier} from "../../../config/roles";
import { useSelector } from "react-redux";
import { formatPrice } from 'components/parts/FormParts/PriceFormatter';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const orderStatuses = ['Ожидает предоплату', 'Передан поставщику', 'Подтвержден поставщиком', 'Отправлен', 'Выполнен', 'Отменен'];

const orderLabels = {
  id_1c: 'Номер заказа',
  created_at: 'Создан',
  updated_at: 'Обновлен',
  deliveryType: 'Способ доставки',
  city: 'Город',
  warehouse: 'Склад',
  customerType: 'Тип Клиента',
  EDRPOU: 'ЕДРПОУ',
  name: 'Имя/Название',
  phone: 'Телефон',
  CODPayer: 'Плательщик за наложку',
  deliveryPayer: 'Плательщик доставки',
  paymentType: 'Способ оплаты',
  insuranceAmount: 'Страховка',
  paymentForm: 'Форма оплаты',
  total_price: 'Общая сумма',  
  pyment_amount: 'Сумма наложки',
  // profit: 'Заработок',
  // volume: 'Объем',
  // weight: 'Вес',
  status: 'Статус',
  ttn: 'ТТН',
  delivery_status: 'Статус посылки',
  expenses: 'Затраты',
  prepayment: 'Предоплата',
  drop_payment_amount: 'Дроп Оплата',
  comment: 'Комментарий',
}

const OrderDetails = ({
  order,
  getOrder,
  classes,
}) => {
  const [formData, setFormData] = useState({})
  const [refresh, setRefresh] = useState(null)
  const { id } = useParams()
  const history = useHistory()
  const isSupplier = onlySupplier();

  useEffect(() => {
    getOrder(id)
  }, [id, getOrder])
  const {
    products,
  } = order
  const isDate = element => element && element.date
  const userData = useSelector((state) => state.userData) || {};

  const handlePay = (e) => {
    e.preventDefault();
    const paymentParams = {
      token: "5058dcfe2859782703547f740d3f1bady4r6t02i",
      parameters: {LS: order.id.toString(), FIO: userData.customerLastname + ' ' + userData.customerName},
      sum: _.round(order.total_price - order.pyment_amount - order.drop_payment_amount, 2)
    };
    window.location.href = `https://next.privat24.ua/payments/form/${JSON.stringify(paymentParams)}`;
  }
  
  const renderDetail = (name, i) => {
    let value = order[name]
    if (isDate(order[name])) {
      value = order[name].date.slice(0, order[name].date.length - 7)
    }
      
      if (['pyment_amount', 'total_price'].includes(name)) {
      value = formatPrice(value) + ' ₴';
    }
    if (name === 'insuranceAmount') {
      value = formatPrice(value, 2) + ' ₴'; 
    }
    // if (name === 'profit') {
    //   value = _.round(order['pyment_amount'] - order['total_price'], 2).toFixed(2);
    // }
    if (name === 'expenses' && onlyAdminOrGreater()) {
      return (
          <div key={name} className={classNames({ [classes.odd]: !(i % 2) }, classes.detail)}>
            <Typography style={{ whiteSpace: 'nowrap', fontSize: '16px', color: '#f0f2f3' }}>{orderLabels[name]} :</Typography>
            <div>
              <TextField variant='outlined' name={name} value={_.isUndefined(formData?.expenses) ? value : formData?.expenses} onChange={event => {
                if (isNaN(event.target.value)) {
                  return;
                }
                setFormData({...formData, expenses: event.target.value});
              } }/>
              <br/>
              <Select
                  variant='outlined'
                  value={_.isUndefined(formData.expenses_target) ? order.expenses_target || 0 : formData.expenses_target}
                  onChange={(event) => {
                    setFormData({...formData, expenses_target: event.target.value})
                  }}
              >
                {['Шиппер', 'Поставщик', 'Платформа'].map((item, index) => (
                    <MenuItem className={classNames(classes.menuItem)} key={index} value={index} style={{ fontSize: '14px' }}>
                      {item}
                    </MenuItem>
                ))}
              </Select>
            </div>
          </div>
      )
    }
    if (name === 'prepayment' && onlyAdminOrGreater()) {
      return (
          <div key={name} className={classNames({ [classes.odd]: !(i % 2) }, classes.detail)}>
            <Typography style={{ whiteSpace: 'nowrap', fontSize: '16px', color: '#f0f2f3' }}>{orderLabels[name]} :</Typography>
            <div>
              <TextField variant='outlined' name={name} value={_.isUndefined(formData?.prepayment) ? value : formData?.prepayment} onChange={event => {
                if (isNaN(event.target.value)) {
                  return;
                }
                setFormData({...formData, prepayment: event.target.value});
              } }/>
            </div>
          </div>
      )
    }
    if (name === 'drop_payment_amount' && onlyAdminOrGreater()) {
      return (
          <div key={name} className={classNames({ [classes.odd]: !(i % 2) }, classes.detail)}>
            <Typography style={{ whiteSpace: 'nowrap', fontSize: '16px', color: '#f0f2f3' }}>{orderLabels[name]} :</Typography>
            <div>
              <TextField variant='outlined' name={name} value={_.isUndefined(formData?.drop_payment_amount) ? value : formData?.drop_payment_amount} onChange={event => {
                if (isNaN(event.target.value)) {
                  return;
                }
                setFormData({...formData, drop_payment_amount: event.target.value});
              } }/>
            </div>
          </div>
      )
    }
    if (name === 'ttn' && (onlyAdminOrGreater() || isSupplier && formData.status === 'Отправлен')) {
      return (
          <div key={name} className={classNames({ [classes.odd]: !(i % 2) }, classes.detail)}>
            <Typography style={{ whiteSpace: 'nowrap', fontSize: '16px', color: '#f0f2f3' }}>{orderLabels[name]} :</Typography>
            <TextField variant='outlined' name={name} value={_.isUndefined(formData?.ttn) ? value : formData?.ttn} onChange={event => setFormData({...formData, ttn: event.target.value})}/>
          </div>
      )
    }
    if (name === 'status' && onlyAdminOrGreater()) {
      return (
          <div key={name} className={classNames({ [classes.odd]: !(i % 2) }, classes.detail)}>
            <Typography style={{ whiteSpace: 'nowrap', fontSize: '16px', color: '#f0f2f3' }}>{orderLabels[name]} :</Typography>
            <Select
                variant='outlined'
                value={formData?.status || value || 'Передан поставщику'}
                onChange={(event) => {
                  setFormData({...formData, status: event.target.value})
                }}
            >
              {orderStatuses.map(status => (
                  <MenuItem className={classNames(classes.menuItem)} key={status} value={status} style={{ fontSize: '14px' }}>
                    {status}
                  </MenuItem>
              ))}
            </Select>
          </div>
      )
    }
    if (name === 'status' && isSupplier) {
      const allowedStatuses = orderStatuses.filter(status => {
        if (value === 'Подтвержден поставщиком') {
          return status === value || status === 'Отправлен' || status === 'Отменен';
        }
        if (value === 'Отправлен') {
          return status === value || status === 'Выполнен' || status === 'Отменен';
        }
        if (value === 'Выполнен') {
          return status === value || status === 'Выполнен' || status === 'Отменен';
        }

        return status === value || status === 'Отменен';
      })
      return (
          <div key={name} className={classNames({ [classes.odd]: !(i % 2) }, classes.detail)}>
            <Typography style={{ whiteSpace: 'nowrap', fontSize: '16px', color: '#f0f2f3' }}>{orderLabels[name]} :</Typography>
            <Select
                variant='outlined'
                value={formData?.status || value || 'Передан поставщику'}
                onChange={(event) => {
                  setFormData({...formData, status: event.target.value})
                }}
            >
              {allowedStatuses.map(status => (
                  <MenuItem className={classNames(classes.menuItem)} key={status} value={status} style={{ fontSize: '14px' }}>
                    {status}
                  </MenuItem>
              ))}
            </Select>
          </div>
      )
    }
    return (
      <div key={name} className={classNames({ [classes.odd]: !(i % 2) }, classes.detail)}>
        <Typography style={{ whiteSpace: 'nowrap', fontSize: '16px', color: '#f0f2f3' }} >
          {orderLabels[name]} 
        </Typography>
        <Typography style={{ whiteSpace: 'break-spaces', fontSize: '14px', color: '#cdcfd1' }} align='right'>
          {value}
        </Typography>
      </div>
    )
  }

  const onSubmit = (e, allowSnack = true) => {
    e.preventDefault();
    if (isSupplier && formData.status === 'Отправлен' && !formData.ttn) {
      showSnack({
        variant: 'warning',
        message: `Заполните номер ТТН для дропшиппера`,
      });
      return;
    }
    updateOrder(id, formData).then((success) => {
      if (success) {
        if (!_.isUndefined(formData.status)) {
          order.status = formData.status;
        }
        if (!_.isUndefined(formData.ttn)) {
          order.ttn = formData.ttn;
        }
        if (!_.isUndefined(formData.expenses)) {
          order.expenses = formData.expenses;
        }
        if (!_.isUndefined(formData.prepayment)) {
          order.prepayment = formData.prepayment;
        }
        if (!_.isUndefined(formData.expenses_target)) {
          order.expenses_target = formData.expenses_target;
        }
        setFormData({});
        if (allowSnack) {
          showSnack({
            variant: 'success',
            message: `Заказ успешно обновлен!`,
          })
        }
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const supplierConfirmOrder = (e) => {
    e.preventDefault()
    formData.status = 'Подтвержден поставщиком';
    onSubmit(e);
  }
  const supplierCancelOrder = (e) => {
    e.preventDefault()
    formData.status = 'Отменен';
    onSubmit(e, false);
    showSnack({
      variant: 'warning',
      message: `Ваш рейтинг будет снижен из-за недостоверности информации и товаре`,
    })
  }

  const sendEmail = (e) => {
    e.preventDefault()
    sendOrderEmail(id).then((success) => {
      if (success) {
        showSnack({
          variant: 'success',
          message: `Email отправлен`,
        })
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  return (
    <Paper style={{ padding: '16px 6px'}}>
      <Typography align='center' variant='h4' color='primary'>
        Заказ № {order.id_1c}
      </Typography>
      <div className={classes.detailsList}>
        <form onSubmit={onSubmit}>
          {isSupplier && order.status === 'Передан поставщику' || Object.keys(orderLabels).filter(key => true).map(renderDetail)}
          {isSupplier && order.status === 'Передан поставщику' &&
              <Grid container md={12} style={{textAlign: 'center' }}>
                <Grid item md={12} >
                  <h2>Подтвердите наличие товара и возможность отправки заказа</h2>
                </Grid>
                <Grid item md={6} >
                  <Button variant='containedPrimary' type='button' sx={{ m: 2 }} onClick={supplierConfirmOrder}>Товар в наличии, отправка возможна 100%</Button>
                </Grid>
                <Grid item md={6} >
                  <Button variant='containedPrimary' type='button' sx={{ m: 2 }} onClick={supplierCancelOrder}>Товар закончился</Button>
                </Grid>
              </Grid>
          }
          <Box display={onlyAdminOrGreater() || (isSupplier && order.status !== 'Передан поставщику') ? "flex" : "none"} justifyContent="flex-end" sx={{ m: 2 }}>
            <Button variant='containedPrimary' type='submit'>Сохранить</Button>
          </Box>
          <Box display={onlyAdminOrGreater() ? "flex" : "none"} justifyContent="flex-end" sx={{ m: 2 }}>
            <Button variant='containedPrimary' type='button' sx={{ m: 2 }} onClick={sendEmail}>Отправить Email</Button>
          </Box>
          <Box display={order.status === 'Ожидает предоплату' ? "flex" : "none"} justifyContent="flex-end" sx={{ m: 2 }}>
            <Button variant='containedPrimary' type='button' sx={{ m: 2 }} onClick={handlePay}>Оплатить</Button>
          </Box>
        </form>
      </div>
      <Table products={products} />
    </Paper>
  )
}

OrderDetails.defaultProps = {
  order: {
    products: [],
  },
}

OrderDetails.propTypes = {
  getOrder: PropTypes.func.isRequired,
  order: PropTypes.object,
  classes: PropTypes.object.isRequired,
}

export default OrderDetails
