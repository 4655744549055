import React, {useEffect, useState} from 'react'
import {CardActions, CardMedia, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {getCategoryWithProducts} from "../../../../services/api/category.service";
import SearchInput from "../../../parts/SearchInput";
import DataTable from "mui-datatables";
import Products from "../Products";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';
import _ from "lodash";
import Divider from "@material-ui/core/Divider";
import WorkOutlineOutlined from '@material-ui/icons/WorkOutlineOutlined'
import BrandingWatermarkOutlined from '@material-ui/icons/BrandingWatermarkOutlined'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import LocalShippingOutlined from "@material-ui/icons/LocalShippingOutlined";
import ShopIcon from '@material-ui/icons/ShoppingCartOutlined'
import PersonOutlined from '@material-ui/icons/PersonOutlined'
import catalogCategoryProductsStyles from './CatalogCategoryProductsStyles';
import { store } from "../../../../storage";
import { providerListPath, feedListPath } from 'config/routes'
import {REMOVE_FROM_CATALOG_CART, UPDATE_CATALOG_CART} from "../../../../storage/constants/catalogCart.constants";
import {useSelector} from "react-redux";
import {showSnack} from "../../../../storage/actions/snack.actions";
import TooltipInfo from "../../../parts/TooltipInfo";
import Tooltip from '@material-ui/core/Tooltip';
import {onlySupplier} from "../../../../config/roles";
import { LocalMallOutlined, StorefrontOutlined } from '@material-ui/icons';

const CatalogCategoryProductsView = (props) => {
    const categoryId = props?.match?.params?.id
    const location = useLocation();
    const history = useHistory();
    const [category, setCategory] = useState({});
    const [categoryInfo, setCategoryInfo] = useState({});
    const isSupplier = onlySupplier();

    useEffect(() => {
        getCategoryWithProducts(categoryId).then((data) => {
            setCategory(data.category);
            setCategoryInfo(data.categoryInfo);
        })
    }, [categoryId]);

    const productsData = useSelector((state) => state.productsData)
    const catalogCart = useSelector((state) => state.catalogCart) || {};
    const filterId = 'category_' + categoryId;
    const isInCatalogCart = catalogCart.filters[filterId];

    const addToCatalogCart = () => {
        if (isInCatalogCart) {
            store.dispatch({
                type: REMOVE_FROM_CATALOG_CART,
                payload: {filterId},
            })
            return;
        }
        if (productsData?.config?.count < 1) {
            showSnack({
                variant: 'error',
                message: ' Нечего добавлять',
            });
            return;
        }
        try {
            store.dispatch({
                type: UPDATE_CATALOG_CART,
                payload: {filters: {[filterId]: productsData?.config}},
            })
            showSnack({
                variant: 'success',
                message: `${productsData?.config.count} товаров добавлено в выгрузку`,
            })
        } catch (e) {
            showSnack({
                variant: 'error',
                message: e.message,
            });
        }
    }

    if (_.isEmpty(category)) {
        return null;
    }
    return (
        <>
            {/* <Grid container md={12} spacing={2} style={catalogCategoryProductsStyles.cardContainer}> */}
                <div style={catalogCategoryProductsStyles.cardContainer}>
                
                <Grid item md={3} style={catalogCategoryProductsStyles.gridItem }>
                    <Card style={catalogCategoryProductsStyles.card }>
                        <CardMedia style={catalogCategoryProductsStyles.cardMedia }
                            component="img"
                            // height="120"
                            image={category.image}
                            alt="image"
                        />
                        <CardContent style={catalogCategoryProductsStyles.cardContent}>
                            <Typography component="div" style={catalogCategoryProductsStyles.listItemText}>
                                {category?.name}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3} style={catalogCategoryProductsStyles.centerGridItem }>
                    <List >
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <WorkOutlineOutlined/>
                            </ListItemIcon>
                            <ListItemText style={catalogCategoryProductsStyles.listItemText}
                                primaryTypographyProps={{ style: { fontSize: 12, lineHeight: 1 } }}
                                primary="Товаров" />
                            <span style={catalogCategoryProductsStyles.spanProducts}>{productsData?.statistics?.products}</span>
                        </ListItem>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <LocalOfferOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText style={catalogCategoryProductsStyles.listItemText}
                                primaryTypographyProps={{ style: { fontSize: 12, lineHeight: 1 } }}
                                primary="Брендов" />
                            <span style={catalogCategoryProductsStyles.spanProducts}>{productsData?.statistics?.vendors}</span>
                        </ListItem>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <StorefrontOutlined/>
                            </ListItemIcon>
                            <ListItemText style={catalogCategoryProductsStyles.listItemText}
                                primaryTypographyProps={{ style: { fontSize: 12, lineHeight: 1 } }}
                                primary="Поставщиков" />
                            <span style={catalogCategoryProductsStyles.spanProducts}>{productsData?.statistics?.providers}</span>
                        </ListItem>
                    </List>

                    {!isSupplier &&
                        <Tooltip
                            title={isInCatalogCart ? "Удаляйте товары из выгрузки" : "Добавляйте выбранные товары в выгрузку"}
                            arrow
                            placement="right"
                        >
                            <Button
                                onClick={addToCatalogCart}
                                variant='contained'
                                color='primary'
                                style={catalogCategoryProductsStyles.exportButton}>
                                {isInCatalogCart ? 'Удалить из выгрузки' : 'Добавить в выгрузку'}
                            </Button>
                        </Tooltip>
                    }
                </Grid>
                <Grid item md={5} style={catalogCategoryProductsStyles.centerGridItem }>
                    <List >
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <FilterListOutlinedIcon/>
                            </ListItemIcon>
                            <Typography
                                color='textSecondary'
                                variant='caption'
                            >
                                {"Добавляйте все товары из этой категории в XML-выгрузку для размещения у вас на сайте или используйте фильтры в таблице"}
                                </Typography>
                        </ListItem>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <CloudUploadOutlinedIcon/>
                            </ListItemIcon>
                            <Typography
                                color='textSecondary'
                                variant='caption'
                            >
                                {"Создать XML-выгрузку с товарами конкретного поставщика также можно в разделе "}
                                <Link
                                        to={providerListPath}
                                        style={{ fontSize: 12, color: '#2792E0', textDecoration: 'underline', display: 'inline-block' }}
                                    >
                                        Поставщики
                                    </Link>
                                </Typography>
                        </ListItem>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <LocalMallOutlined/>
                            </ListItemIcon>
                            <Typography
                                color='textSecondary'
                                variant='caption'
                            >
                                {"Создавайте до 3-х XML-выгрузок для разных торговых площадок. Все созданные вами выгрузки будут доступны в разделе "}
                                <Link
                                        to={feedListPath}
                                        style={{ fontSize: 12, color: '#2792E0', textDecoration: 'underline', display: 'inline-block' }}
                                    >
                                        Мои XML выгрузки
                                    </Link>
                                </Typography>
                        </ListItem>
                    </List>
                </Grid>
                </div>
            <br/>
            <TooltipInfo open={true} title={'Для добавления товаров в выгрузку можно использовать фильтры'}>
                <div style={{
                    height: 50,
                    width: 100,
                    position: 'absolute',
                    top: '140px',
                    left: '90%'
                }}
                />
            </TooltipInfo>
            <Products history={history} location={location} category={category}></Products>
        </>
    );
}

export default CatalogCategoryProductsView
