import React, { useEffect } from 'react'
import IconHome from '@material-ui/icons/HomeOutlined'
import ShopIcon from '@material-ui/icons/ShoppingCartOutlined'
import BagIcon from '@material-ui/icons/WorkOutlineOutlined'
import CreditCardIcon from '@material-ui/icons/CreditCardOutlined'
import AccountIcon from '@material-ui/icons/AccountBoxOutlined'
import IconAdd from '@material-ui/icons/Add'
import Category from '@material-ui/icons/Category'
import People from '@material-ui/icons/People'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'


import { selectMenuAction } from 'storage/actions/menu.actions'

import {
  ordersPath,
  productsPath,
  profilePath,
  statisticPath,
  userListPath,
  productImportPath,
  withdrawalsPath, providerListPath, feedListPath, productXmlImportPath,
} from 'config/routes'
import {
  superAdmin,
  newUser,
  admin,
  declineRoles, supplier,
} from 'config/roles'
import {
  AccountBalanceWalletOutlined,
  CloudDownloadOutlined,
  FolderOpenOutlined,
  LocalShippingOutlined, ReportOutlined, InfoOutlined, TimelineOutlined, LocalMallOutlined, PersonOutlineOutlined, StorefrontOutlined
} from "@material-ui/icons";
import {sendOrderEmail} from "../../../services/api/order.service";
import {showSnack} from "../../../storage/actions/snack.actions";
import {getCategoriesByLevel} from "../../../services/api/category.service";

const menuSelectDispatcher = dispatch => ({
  select: item => dispatch(selectMenuAction(item ? item.idx || -1 : -1)),
})

const menuConfig = [
  {
    idx: 0,
    primary: 'Дроп-баланс',
    icon: () => <TimelineOutlined />,
    path: statisticPath,
    allowedRoles: declineRoles([newUser]),
  },
  {
    idx: 1,    
    primary: 'Мои заказы',
    icon: () => <ShopIcon />,
    path: ordersPath,
    allowedRoles: declineRoles([newUser]),
  },
  {
    idx: 2,    
    primary: 'Мои выплаты',
    icon: () => <AccountBalanceWalletOutlined />,
    path: withdrawalsPath,
    allowedRoles: declineRoles([newUser, supplier]),
  },
  {
    idx: 3,    
    primary: 'Каталог',
    icon: () => <FolderOpenOutlined />,
    path: '',
    subMenuItems: [],
    allowedRoles: declineRoles([newUser]),
  },
  {
    idx: 4,    
    primary: 'Поставщики',
    iconFontSize: 'large',
    icon: () => <StorefrontOutlined />,
    path: providerListPath,
    allowedRoles: declineRoles([newUser, supplier]),
  },
  {
    idx: 5,    
    primary: 'Мои XML выгрузки',
    icon: () => <LocalMallOutlined />,
    path: feedListPath,
    allowedRoles: declineRoles([newUser, supplier]),
  },
  {
    idx: 6,
    primary: 'Мой профиль',
    iconFontSize: 'large',
    icon: () => <PersonOutlineOutlined />,
    path: profilePath,
    allowedRoles: declineRoles([newUser]),
    endIcon: <ReportOutlined fontSize="medium" style={{ color: 'red' }}/>
  },
  {
    idx: 7,
    primary: 'Дроп Инфо',
    icon: () => <InfoOutlined />,
    path: '',
    subMenuItems: [
      {
        idx: 10,
        name: 'Условия работы',
        path: '/working-conditions'
      },
      {
        idx: 11,
        name: 'Вопрос/Ответ',
        path: '/question-answer'
      },
      {
        idx: 12,
        name: 'Начать продавать на Prom.ua',
        path: '/sell-on-prom-ua'
      },
    ],
    allowedRoles: declineRoles([newUser, supplier]),
  },
  {
    idx: 8,
    primary: 'Пользователи',
    iconFontSize: 'large',
    icon: () => <People />,
    path: userListPath,
    allowedRoles: [admin, superAdmin],
  },
  {
    idx: 9,
    primary: 'Импорт Товаров Админ',
    icon: () => <CloudDownloadOutlined />,
    path: productImportPath,
    allowedRoles: [admin, superAdmin],
  },
  {
    idx: 10,
    primary: 'Импорт Товаров',
    icon: () => <CloudDownloadOutlined />,
    path: productXmlImportPath,
    allowedRoles: [supplier],
  },
]

const RouterListener = connect(null, menuSelectDispatcher)(withRouter(({ history, select }) => {
  useEffect(() => {
    select(menuConfig.find(item => item.path === history.location.pathname))
    history.listen((url) => {
      const { pathname } = url
      select(menuConfig.find(item => item.path === pathname))
    })
  }, [])
  return <></>
}))

export default menuConfig
export { RouterListener, menuConfig }
