import React, {useEffect, useState} from 'react'
// import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import MUIDataTable from "mui-datatables";
import { getDashboardData } from "../../../services/api/dashboard.service";
import { withdraw } from "../../../services/api/withdrawal.service";
import Paper from '@material-ui/core/Paper'
import { ordersPath, orderDetailsPath, withdrawalsPath } from 'config/routes'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { chipStyles, renderStatusChip } from './ChipStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import { CardMedia, Dialog, DialogActions, DialogTitle, Grid, TextField } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {showSnack} from "../../../storage/actions/snack.actions";
import MaskedPhone from "../../assets/MaskedPhone";
import __ from "lodash/fp/__";
import {onlyAdminOrGreater, onlySupplier} from "../../../config/roles";
import EarningsCard from './EarningsCard';
import WithdrawalsTable from './WithdrawalsTable';
import WithdrawalDialog from './WithdrawalDialog';
import { textLabels } from 'config/tableConfig/textLabels'
import Tooltip from '@material-ui/core/Tooltip';


const DashboardView = (props) => {
    const [dashboardData, setDashboardData] = useState({});
    const [modalOpened, setModalOpened] = useState(false);
    const classes = chipStyles();
    const theme = useTheme();
    const isButtonActive = dashboardData?.earnings?.available > 0 && !onlyAdminOrGreater();
    const isSupplier = onlySupplier();

    useEffect(() => {
        if (dashboardData?.earnings) {
            return;
        }
        getDashboardData().then((data) => {
            setDashboardData(data);
        })
    }, []);

    function handleWithdrawClick() {
    withdraw().then((data) => {
        const roundedAmount = Math.round(parseFloat(dashboardData?.earnings?.available));
        showSnack({
            variant: 'success',
            message: `Ваш запрос на выплату ${roundedAmount} ₴ отправлен!`,
        });
        getDashboardData().then((data) => {
            setDashboardData(data);
            setModalOpened(false);
        });
    });
}

    const history = useHistory()

    const columns = [
        {name: 'id', label: 'Номер'},
        {name: 'name', label: 'Получатель'},
        {
            name: 'total_price',
            label: 'Сумма',
            options: {
                customBodyRender: value => `${Math.round(value)} ₴`
            }
        },
        {
            name: 'status', label: 'Статус', options: {
                customBodyRender: (value) => renderStatusChip(value, classes),
            },
        },
        { name: 'ttn', label: 'ТТН'},
        { name: 'delivery_status', label: 'Статус Посылки'},
        {
            name: 'created_at',
            label: 'Дата',
            options: {customBodyRender: value => new Date(value).toLocaleDateString('ru-RU')}
        }
    ]

    const findProductByRowMeta = row => dashboardData?.orders
    .find(order => order.id === row[columns.findIndex(({ name }) => name === 'id')])

    const options = {
        download: false,
        print: false,
        search: false,
        filter: false,
        viewColumns: false,
        selectableRowsHeader: false,
        selectableRows: 'none',
        pagination: false,
        textLabels,
        customToolbar: null,
        onRowClick: (row) => {
            history.push(orderDetailsPath.replace(':id', findProductByRowMeta(row).id))
        },
    }


    return (
        <>
            <Grid container md={12} spacing={2} style={{margin: '1px'}}>
                <Grid item md={5} >
                    <Card fullWidth>
                        <CardContent>
                            {/* <Tooltip
                                title="Доходы по заказам и баланс"
                                arrow
                                placement="right"
                            > */}
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Typography
                                    color='textPrimary'
                                    variant='caption'
                                    style={{ fontSize: 24, textTransform: 'uppercase' }}
                                >
                                    {"ДОХОДЫ"}
                                </Typography>
                                <Typography
                                    color='textSecondary'
                                    variant='caption'
                                    style={{ fontSize: 12 }}
                                >
                                    { !isSupplier ? "Здесь отображается ваш финансовый баланс: доступные средства к выплате, ожидаемый доход, затраты" : "Здесь отображается баланс поставщика: фактический доход, ожидаемый доход, затраты"}
                                </Typography>
                            </div>
                                {/* </Tooltip> */}
                            <Typography style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '20px 0' }}>
                                <EarningsCard
                                    amount={_.round(dashboardData?.earnings?. available, 2).toFixed(2)}
                                    label={ !isSupplier ? "Дроп баланс" : "Доход"}
                                    additionalLabel={ !isSupplier ? "Доступная к выплате сумма вашего заработка. Формируется из успешно выполненных заказов" : "Доход по успешно выполненным заказам"}
                                    status="available" />
                                <EarningsCard
                                    amount={_.round(dashboardData?.earnings?.pending, 2).toFixed(2)}
                                    label="Ожидаемый доход"
                                    additionalLabel={ !isSupplier ? "Ожидаемый доход по отправленным и еще не выполненным заказам" : "Ожидаемый доход по отправленным и еще не выполненным заказам"}
                                    status="pending" />
                                <EarningsCard
                                    amount={_.round(dashboardData?.earnings?.expenses, 2).toFixed(2)}
                                    label="Затраты"
                                    additionalLabel={ !isSupplier ? "Расходы по доставке, незабранным заказам, возвратам" : "Расходы по доставке, незабранным заказам, возвратам"}
                                    status="expenses" />
                            </Typography>
                            {/* <Button disabled={!dashboardData?.earnings?.available || onlyAdminOrGreater()} variant='contained' onClick={() => {setModalOpened(true)}}>Запросить выплату</Button> */}
                            {
                                !isSupplier &&
                                <Button
                                    disabled={!isButtonActive}
                                    variant='contained'
                                    color={isButtonActive ? 'primary' : 'default'}
                                    onClick={() => { setModalOpened(true) }}
                                >
                                    Запросить выплату
                                </Button>
                            }
                        </CardContent>
                    </Card>
                </Grid>
                {
                    !isSupplier &&
                    <Grid item md={7} >
                    <Card fullWidth>
                        <CardContent>
                            {/* <Tooltip
                                title="Запросы на выплаты"
                                arrow
                                placement="right"
                            > */}
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Typography
                                color='textPrimary'
                                variant='caption'
                                style={{ fontSize: 24, textTransform: 'uppercase'}}
                            >
                                {"Выплаты"}
                                </Typography>
                                <Typography
                                    color='textSecondary'
                                    variant='caption'
                                    style={{ fontSize: 12 }}
                                >
                                    { !isSupplier ? "Здесь отображаются ваши последние выплаты с дроп-баланса." : ""}
                                    <br />
                                    { !isSupplier ? "Вся информация по запросам на выплаты доступна в разделе " : ""}
                                    { !isSupplier && <Link
                                    to={withdrawalsPath}
                                    style={{ fontSize: 12, color: '#2792E0', textDecoration: 'underline', display: 'inline-block' }}
                                >
                                    Мои выплаты
                                </Link>}

                                </Typography>
                            </div>
                            {/* </Tooltip> */}
                            <WithdrawalsTable data={dashboardData?.withdrawals} />
                        </CardContent>
                    </Card>
                </Grid>
                }
            </Grid>
            <WithdrawalDialog
                modalOpened={modalOpened}
                handleWithdrawClick={handleWithdrawClick}
                onClose={() => setModalOpened(false)}
                availableAmount={dashboardData?.earnings?.available}/>
            <Paper>
                <Card fullWidth>
                <CardContent>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            color='textPrimary'
                            variant='caption'
                            style={{ fontSize: 24, textTransform: 'uppercase' }}
                        >
                            {"Последние заказы"}
                        </Typography>
                        <Typography
                            color='textSecondary'
                            variant='caption'
                            style={{ fontSize: 12 }}
                        >
                            { !isSupplier ? "Здесь отображаются ваши последние оформленные заказы." : "Здесь отображаются последние заказы на ваши товары, оформленные на платформе."}<br />
                            {/* {"Здесь отображаются ваши последние оформленные заказы."} */}
                            {"Информация по всем вашим заказам доступна в разделе "}
                            <Link
                                to={ordersPath}
                                style={{ fontSize: 12, color: '#2792E0', textDecoration: 'underline', display: 'inline-block'}}
                            >Мои заказы</Link>
                        </Typography>
                    </div>
                </CardContent>
            </Card>
                
            <MUIDataTable
                columns={columns}
                data={dashboardData?.orders}
                title={
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '5px', gap: '5px' }}>
                            <Typography variant="caption" component="span">
                                Передан поставщику: <span style={{ fontWeight: 'bold', color: theme.palette.info.main }}>{dashboardData?.orderInfo?.countByStatuses?.transferredToSupplier || 0}</span>
                            </Typography>
                            <Typography variant="caption" component="span">
                                Отправлен: <span style={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>{dashboardData?.orderInfo?.countByStatuses?.sent || 0}</span>
                            </Typography>
                            <Typography variant="caption" component="span">
                                Выполнен: <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>{dashboardData?.orderInfo?.countByStatuses?.completed || 0}</span>
                            </Typography>
                        </div>
                    </>
                }
                options={options}
            />
        </Paper>
        </>
    );
}

export default DashboardView
