import React, {useEffect, useState} from 'react'
import Paper from '@material-ui/core/Paper'
import DataTable from 'mui-datatables'
import {getAllWithdrawals, getWithdrawal, updateWithdrawal} from "../../../services/api/withdrawal.service";
import {useHistory, useLocation} from "react-router-dom";
import { Typography, IconButton } from '@material-ui/core';
import {Box, CardMedia, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select} from "@material-ui/core";
import {onlyAdminOrGreater} from "../../../config/roles";
import Button from "@material-ui/core/Button";
import Table from "../OrderDetails/Table";
import classNames from "classnames";
import {updateOrder} from "../../../services/api/order.service";
import {showSnack} from "../../../storage/actions/snack.actions";
import {getProvider} from "../../../services/api/provider.service";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import WorkOutlineOutlined from "@material-ui/icons/WorkOutlineOutlined";
import BrandingWatermarkOutlined from "@material-ui/icons/BrandingWatermarkOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import _ from "lodash";
import Products from "../products/Products";
import {deleteFeed, getFeed} from "../../../services/api/feed.service";
import catalogCategoryProductsStyles from "../products/CatalogCategoryProductsView/CatalogCategoryProductsStyles";
import {feedDetailsPath, feedListPath} from "../../../config/routes";
import TextField from "@material-ui/core/TextField";
import { apiBaseURL } from "../../../config/constants";
import { Tooltip } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';


const FeedDetails = (props) => {
    const id = props?.match?.params?.id
    const classes = props?.classes

    const [feed, setFeed] = useState({});
    const history = useHistory();
    const location = useLocation();

    const xmlLink = apiBaseURL + '/feeds/' + feed?.id + '.xml' ;

    useEffect(() => {
        getFeed(id).then((data) => {
            setFeed(data);
        }).catch(() => {
            history.push(feedListPath)
        })
    }, []);

    function handleDelete() {
        deleteFeed(id).then(() => {
            history.push(feedListPath);

            showSnack({
                variant: 'success',
                message: `Запись успешно удалена`,
            })
        })
    }
    const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
        alert("ХМЛ-выгрузка скопирована! Теперь можете использовать ее для импорта товаров на вашем сайте"); 
    }).catch(() => {
        alert("Ошибка при копировании");
    });
};

    return ( 
        // <Grid container spacing={2} style={{ padding: 10 }}>
        //     <Grid item xs={12}>
        //         <Paper elevation={0}>
        //             <Grid container spacing={2} style={{ padding: 16 }} >
        //                 <Grid item xs={12} sm={6} >
        //                     <Grid container spacing={1} >
        //                         <Grid item xs={12} sm={6}>
        //                             <Typography
        //                                 component='div'
        //                                 variant='body1'
        //                                 paragraph
        //                             >
        //                             Название XML-выгрузки:
        //                             </Typography>
        //                         </Grid>
        //                         <Grid item xs={12} sm={6}>
        //                             <Typography
        //                                 component='div'
        //                                 variant='body1'
        //                                 paragraph>
        //                                 {feed.name}
        //                             </Typography>
        //                         </Grid>
        //                         <Grid item xs={12} sm={6}>
        //                             <Typography
        //                                 component='div'
        //                                 variant='body1'
        //                                 paragraph
        //                             >
        //                             XML-выгрузка: 
        //                             </Typography>
        //                         </Grid>
        //                         <Grid item xs={12} sm={6}>
        //                             <Typography component="div" variant="body1" paragraph>
        //                                 <span
        //                                     onClick={() => copyToClipboard(xmlLink)}
        //                                     style={{
        //                                     color: "#DB4C19",
        //                                     textDecoration: "underline",
        //                                     cursor: "pointer",
        //                                     wordBreak: 'break-word',
        //                                     }}
        //                                 >
        //                                     {xmlLink}
        //                             </span>
        //                             </Typography>
        //                         </Grid>
        //                         <Grid item xs={12}>      
        //                         <Button
        //                             color='primary'
        //                             variant='contained'
        //                             onClick={handleDelete}
        //                             size='small'
        //                             style={{ height: 31, padding: '6px 16px' }}
        //                         >
        //                             Удалить XML-выгрузку
        //                         </Button>
        //                         </Grid>
        //                     </Grid>
        //                 </Grid>
        //                 </Grid>
        //             <Products history={history} location={location} feedId={id} ></Products>
        //         </Paper>
        //     </Grid> 
        // </Grid>
        <div style={{ padding: 10 }}>
    <Paper elevation={0} style={{ padding: 16 }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <div style={{ display: 'flex', gap: 8 }}>
                <Typography variant='body1'>Название XML-выгрузки:</Typography>
                <Typography variant='body1' style={{ fontWeight: 500, color: '#DB4C19' }}>
                    {feed.name}
                </Typography>
            </div>

            <div style={{ display: 'flex', gap: 8, alignItems: 'center', flexWrap: 'wrap' }}>
    <Typography variant='body1'>XML-выгрузка:</Typography>
    
    <Typography 
        variant='body1' 
        onClick={() => copyToClipboard(xmlLink)}
        style={{ 
            color: "#2792E0", 
            textDecoration: "underline", 
            cursor: "pointer", 
            wordBreak: 'break-word' 
        }}
    >
        {xmlLink}
    </Typography>

    <Tooltip title="Скопировать XML">
        <IconButton 
            onClick={() => copyToClipboard(xmlLink)} 
            size="small" 
            // style={{ marginLeft: 4 }}
            aria-label="Скопировать XML"
        >
            <FileCopyOutlinedIcon fontSize="small" />
        </IconButton>
    </Tooltip>
    </div>

        <div style={{ display: 'flex', gap: 8, alignItems: 'center', flexShrink: 0 }}>
            <InfoOutlinedIcon color="secondary" fontSize="small" />
                <Typography variant='body2'>
                    В таблице ниже отображаются все товары, содержащиеся в этой ХМЛ-выгрузке
                </Typography>
        </div>

            <Button color='primary' variant='contained' onClick={handleDelete} size='small' style={{ height: 31, alignSelf: 'start' }}>
                Удалить XML-выгрузку
            </Button>
        </div>
    </Paper>

    <Products history={history} location={location} feedId={id} />
</div>
    )
}

export default FeedDetails
