import React, {useEffect, useState} from 'react'
import Paper from '@material-ui/core/Paper'
import DataTable from 'mui-datatables'
import Typography from '@material-ui/core/Typography'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {getAllWithdrawals, withdraw} from "../../../services/api/withdrawal.service";
import {withdrawalDetailsPath} from "../../../config/routes";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import {onlyAdminOrGreater, onlySupplier} from "../../../config/roles";
import { statisticPath } from 'config/routes'; 
import SearchInput from "../../parts/SearchInput";
import { textLabels } from 'config/tableConfig/textLabels'
import { chipStyles, renderStatusChip } from '../Dashboard/ChipStyles';
import { formatPrice } from 'components/parts/FormParts/PriceFormatter';
import Button from "@material-ui/core/Button";
import {getDashboardData} from "../../../services/api/dashboard.service";
import WithdrawalDialog from "../Dashboard/WithdrawalDialog";
import {showSnack} from "../../../storage/actions/snack.actions";


const WithdrawalList = () => {

    const [dashboardData, setDashboardData] = useState({});
    const [withdrawals, setWithdrawals] = useState([]);
    const [withdrawalsSearch, setWithdrawalsSearch] = useState(null);
    const [modalOpened, setModalOpened] = useState(false);
    const isSupplier = onlySupplier();
    const isButtonActive = dashboardData?.earnings?.available > 0 && !onlyAdminOrGreater();

    useEffect(() => {
        getAllWithdrawals().then((data) => {
            setWithdrawals(data.reverse());
        })
        getDashboardData().then((data) => {
            setDashboardData(data);
        })
    }, [modalOpened]);

    const history = useHistory()

    const columns = [
        {name: 'id', label: 'Номер'},
        {
            name: 'customer_name',
            label: 'Шиппер',
            options: {
                customBodyRender: (value, row) => {
                    const currentEntity = withdrawals[row.rowIndex];
                    return `${currentEntity.customer_name} ${currentEntity.customer_lastname}`;
                },
                sort: false,
                display: onlyAdminOrGreater(),
            }
        },
        {
            name: 'sum',
            label: 'Сумма',
            options: {
                customBodyRender: value => formatPrice(value) + ' ₴',
            },
        },
        // {name: 'status', label: 'Статус'},
        {
            name: 'status',
            label: 'Статус',
            options: {
                customBodyRender: (value) => renderStatusChip(value, chipClasses),
            },
        },
        {
            name: 'created_at',
            label: 'Дата создания',
            options: {
                customBodyRender: (value) => new Date(value).toLocaleDateString(),
            },
        },
    ]

    const options = {
        download: false,
        print: false,
        search: false,
        filter: false,
        viewColumns: false,
        selectableRowsHeader: false,
        selectableRows: 'none',
        pagination: true,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 50, 100],
        onRowClick: (row) => {
            history.push(withdrawalDetailsPath.replace(':id', row[0]))
        },
        textLabels,
    }

    const onSearch = (searchQuery) => {
        setWithdrawalsSearch(withdrawals.filter(withdrawal => {
            var regExp = new RegExp(searchQuery, 'i')
            return withdrawal.status.search(regExp) !== -1
                || withdrawal.created_at.search(regExp) !== -1
                || withdrawal.sum.search(regExp) !== -1;
        }))
    }

    function handleWithdrawClick() {
        withdraw().then((data) => {
            const roundedAmount = Math.round(parseFloat(dashboardData?.earnings?.available));
            showSnack({
                variant: 'success',
                message: `Ваш запрос на выплату ${roundedAmount} ₴ отправлен!`,
            });
            getDashboardData().then((data) => {
                setDashboardData(data);
                setModalOpened(false);
            });
        });
    }

    const chipClasses = chipStyles();

    return (
        <Paper>
            <Card fullWidth>
                <CardContent>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography
                            color='textPrimary'
                            variant='caption'
                            style={{ fontSize: 24, textTransform: 'uppercase' }}
                        >
                            {"Мои выплаты"}
                        </Typography>
                        <Typography
                            color='textSecondary'
                            variant='caption'
                            style={{ fontSize: 12 }}
                        >
                            {"Здесь отображается список всех ваших выплат."}<br />
                            {"Сформировать запрос на выплату можно при наличии средств на дроп-балансе, который формируется из заработка по успешно выполненным заказам."}<br />
                            {"Вся информация по вашему балансу доступна в разделе "}
                            <Link
                                to={statisticPath}
                                style={{ fontSize: 12, color: '#2792E0', textDecoration: 'underline', display: 'inline-block'}}
                            >Дроп-баланс</Link>
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                        <Typography
                            color='textSecondary'
                            variant='body1'
                            // style={{ fontSize: 14 }}
                        >
                            Доступно к выплате: {Math.round(parseFloat(dashboardData?.earnings?.available))} ₴
                        </Typography>
                        {/* <Typography> */}
                            {
                                !isSupplier &&
                                <Button
                                    disabled={!isButtonActive}
                                    variant='contained'
                                    color={isButtonActive ? 'primary' : 'default'}
                                    onClick={() => { setModalOpened(true) }}
                                    style={{ width: 'fit-content', marginTop: '5px' }}
                                >
                                    Запросить выплату
                                </Button>
                            }
                        {/* </Typography> */}
                    </div>

                </CardContent>
            </Card>
            <DataTable
                data={withdrawalsSearch ? withdrawalsSearch : withdrawals}
                options={options}
                columns={columns}
                title={<SearchInput tooltipsOpened={false} onSearch={onSearch} position='start' placeholder={'Поиск'}/>}
            />
            <WithdrawalDialog
            modalOpened={modalOpened}
            handleWithdrawClick={handleWithdrawClick}
            onClose={() => setModalOpened(false)}
            availableAmount={dashboardData?.earnings?.available}/>
        </Paper>
    )
}

export default WithdrawalList
