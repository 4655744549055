import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { Typography, Container, List, ListItem, Box, ListItemIcon } from '@material-ui/core';
import ShopIcon from '@material-ui/icons/ShoppingCartOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import BagIcon from '@material-ui/icons/WorkOutlineOutlined';
import {
  AccountBalanceWalletOutlined, AssignmentTurnedInOutlined, VerifiedUserOutlined,
  LocalShippingOutlined, SyncOutlined,
  LocalMallOutlined
} from "@material-ui/icons";
import { providerListPath, feedListPath } from "../../../config/routes";
import { Link } from 'react-router-dom';


const WorkingConditions = (props) => {
    return (
        <Paper style={{ padding: '16px 6px'}}>
            <Container style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                    variant='h6'
                    paragraph
                    align='center'
                    style={{ fontSize:  20 }}
                >
                    Добро пожаловать в ваш личный кабинет дропшиппера!<br/>
                    Теперь, когда вы зарегистрировались на нашей платформе, мы предлагаем ознакомиться с условиями работы:
                </Typography>
                <Box display="flex" alignItems="center" mb={2} mt={2}>
                    <ShopIcon color="primary" style={{ marginRight: '8px' }} />
                    <Typography variant='h5' color='primary' >
                        Заказы
                    </Typography>
                </Box>
                <Typography variant="body1" style={{ fontWeight:  500 }}>
                    Оформление заказов
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Заказы оформляются через каталог товаров. Выберите нужный товар в каталоге, добавьте его в корзину и выберите способ оплаты
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Мы поддерживаем наложенный платеж, предоплату, OLX доставку и ПРОМ-оплату, оплату по безналу
                        </Typography>
                    </ListItem>
                </List>
                
                <Typography variant="body1" style={{ fontWeight:  500 }}>
                    Статусы заказов
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Статусы заказов отображаются в каждом заказе после его оформления
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Статусы доставки отображаются в заказе после внесения в него ТТН
                        </Typography>
                    </ListItem>
                </List>

                <Box display="flex" alignItems="center" mb={2} mt={2}>
                    <LocalShippingOutlined color="primary" style={{ marginRight: '8px' }} />
                    <Typography variant='h5' color='primary'>
                        Оплата и доставка
                    </Typography>
                </Box>
                <Typography variant="body1" style={{ fontWeight:  500 }}>
                    Способы доставки
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            На данный момент мы отправляем заказы Новой Почтой. В будущем будут добавляться другие службы доставки
                        </Typography>
                    </ListItem>
                </List>
                
                <Typography variant="body1" style={{ fontWeight:  500 }}>
                    Способы оплаты.
                </Typography>
                <Typography variant="body2">
                    Мы отправляем ваши заказы с такими вариантами оплаты:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Наложкой - оплата заказа клиентом после осмотра на Новой Почте
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Без наложки - вы оплачиваете заказ на счет платформы по дроп-цене
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            ОЛХ-доставкой - по вашей ТТН, вы оплачиваете заказ на счет платформы по дроп-цене
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            ПРОМ-оплатой - по нашей ТТН, вы оплачиваете заказ на счет платформы по дроп-цене
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Оплата заказов по безналу с НДС и без НДС - выставляем счета от ФОП или ООО
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Также возможна оплата заказов с дроп-баланса - при наличии у вас средства на дроп-балансе, сумма заказа спишется с баланса по дроп-цене
                        </Typography>
                    </ListItem>
                </List>
                <Typography variant="body1" style={{ fontWeight:  500 }}>
                    Наложенный платеж и предоплата
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Заказы наложкой отправляются от имени платформы
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Наложка приходит на счет платформы и зачисляется на ваш дроп-баланс. После чего вы можете запросить выплату своих средств
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Возможны отправки заказов по вашей ТТН. Такие заказы оплачиваются платформе по предоплате
                        </Typography>
                    </ListItem>
                </List>

                <Box display="flex" alignItems="center" mb={2} mt={2}>
                    <AccountBalanceWalletOutlined color="primary" style={{ marginRight: '8px' }} />
                    <Typography variant='h5' color='primary' >
                        Выплаты
                    </Typography>
                </Box>
                <Typography variant="body1" style={{ fontWeight:  500 }}>
                    Выплаты и комиссии
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Выплаты на вывод ваших средств осуществляются по запросу без минимальной суммы
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Выплаты осуществляются день в день, на вашу карту или счет
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Для выплат заполните карту или счет в вашем профиле и сформируйте запрос на выплату
                        </Typography>
                    </ListItem>
                </List>
                
                <Typography variant="body1" style={{ fontWeight:  500 }}>
                    Возвраты
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Возвраты заказов возможны в случае отказа клиента от получения посылки на Новой почте
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Возврат товара в случае брака или не соответствующего заказу товара оплачивает платформа
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Расходы за доставку и возврат товара оплачивает дропшиппер
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Расходы дропшиппера отнимаются от суммы заработанных средств при формировании выплат
                        </Typography>
                    </ListItem>
                </List>

                <Box display="flex" alignItems="center" mb={2} mt={2}>
                    <SyncOutlined color="primary" style={{ marginRight: '8px' }} />
                    <Typography variant='h5' color='primary' >
                        Обновление и актуальность информации
                    </Typography>
                </Box>
                <Typography variant="body1" style={{ fontWeight:  500 }}>
                    Актуальность товаров
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Актуальные цены РРЦ, дроп-цены и наличие товаров отображаются в каталоге товаров
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Цены, наличие товаров на платформе обновляются автоматически каждый час
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            {"Наценка и рейтинг определенного поставщика доступны в разделе "} 
                            <Link to={providerListPath} style={{ color: '#2792E0', textDecoration: 'underline' }}>
                                Поставщики
                            </Link>
                        </Typography>
                    </ListItem>
                </List>

                <Box display="flex" alignItems="center" mb={2} mt={2}>
                    <LocalMallOutlined color="primary" style={{ marginRight: '8px' }} />
                    <Typography variant='h5' color='primary' >
                        ХМЛ-выгрузки и импорт товаров
                    </Typography>
                </Box>
                <Typography variant="body1" style={{ fontWeight:  500 }}>
                    Создание XML-выгрузок в каталоге
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Вы можете создавать до 3-х XML-выгрузок для импорта товаров с платформы на маркетплейсы или на свой сайт
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Выгрузки автоматически обновляются каждый час либо при выполнении импорта с вашего сайта
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            {"Созданные выгрузки вы найдете в разделе "}
                                <Link to={feedListPath} style={{ color: '#2792E0', textDecoration: 'underline' }}>
                                    Мои XML выгрузки
                                </Link>
                        </Typography>
                    </ListItem>
                </List>

                <Box display="flex" alignItems="center" mb={2} mt={2}>
                    <AssignmentTurnedInOutlined color="primary" style={{ marginRight: '8px' }} />
                    <Typography variant='h5' color='primary' >
                        Обязанности дропшипперов и платформы
                    </Typography>
                </Box>
                <Typography variant="body1" style={{ fontWeight:  500 }}>
                    Обязанности дропшипперов
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Дропшиппер обязуется активно работать над продажами товаров на своих ресурсах
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Консультировать клиента по товарам, по условиям оплаты и срокам отправки заказов
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Дропшиппер и только дропшиппер контактирует со своими клиентами по любым вопросам, таким как консультации по товару, по заказу, статусам отправки и прочим
                        </Typography>
                    </ListItem>
                </List>
                
                <Typography variant="body1" style={{ fontWeight:  500 }}>
                    Обязанности платформы
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Платформа обязуется своевременно обновлять цены и наличие товаров в каталоге
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Своевременно отправлять заказы клиентам дропшипперов
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Своевременно делать выплаты по запросам дропшипперов
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Платформа не контактирует с клиентами дропшипперов, ни по заказам, ни по ТТН
                        </Typography>
                    </ListItem>
                </List>

                <Box display="flex" alignItems="center" mb={2} mt={2}>
                    <VerifiedUserOutlined color="primary" style={{ marginRight: '8px' }} />
                    <Typography variant='h5' color='primary' >
                        Безопасность данных
                    </Typography>
                </Box>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small' />
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Мы обрабатываем персональные данные в соответствии с законодательством и обеспечиваем их защиту
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Ваши данные не будут передаваться третьим лицам
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CheckOutlinedIcon color="primary" fontSize='small'/>
                        </ListItemIcon>
                        <Typography variant="body2" fontSize="14px">
                            Номер вашей карты или счета будет использоваться только для выплат ваших средств
                        </Typography>
                    </ListItem>
                </List>        
                </Container>
            </Paper>
    );
}

export default WorkingConditions
