import React, {useEffect, useState} from 'react'
import catalogCategoryProductsStyles from "../CatalogCategoryProductsView/CatalogCategoryProductsStyles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {CardMedia, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import WorkOutlineOutlined from "@material-ui/icons/WorkOutlineOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import LocalShippingOutlined from "@material-ui/icons/LocalShippingOutlined";
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import ShopIcon from '@material-ui/icons/ShoppingCartOutlined'
import PersonOutlined from "@material-ui/icons/PersonOutlined";
import Button from "@material-ui/core/Button";
import Products from "../Products";
import {getStatistics} from "../../../../services/api/products.service";
import {useSelector} from "react-redux";
import { store } from "../../../../storage";
import { Link } from 'react-router-dom';
import {REMOVE_FROM_CATALOG_CART, UPDATE_CATALOG_CART} from "../../../../storage/constants/catalogCart.constants";
import {showSnack} from "../../../../storage/actions/snack.actions";
import { getProvider } from "../../../../services/api/provider.service";
import { providerListPath, feedListPath, productXmlImportPath } from 'config/routes'
import Tooltip from '@material-ui/core/Tooltip';
import {onlySupplier} from "../../../../config/roles";
import { LocalMallOutlined, StorefrontOutlined } from '@material-ui/icons';

export default function ProductList(props) {
    var providerId = props?.match?.params?.provider;
    const [provider, setProvider] = useState({});
    const isSupplier = onlySupplier();

    useEffect(() => {
        if (!providerId) {
            return;
        }
        getProvider(providerId).then((data) => {
            setProvider(data);
        })
    }, [provider.id]);

    const productsData = useSelector((state) => state.productsData)
    const catalogCart = useSelector((state) => state.catalogCart) || {};
    const filterId = 'all_products';
    const isInCatalogCart = catalogCart.filters[filterId];

    const addToCatalogCart = () => {
        if (isInCatalogCart) {
            store.dispatch({
                type: REMOVE_FROM_CATALOG_CART,
                payload: {filterId},
            })
            return;
        }
        if (productsData?.config?.count < 1) {
            showSnack({
                variant: 'error',
                message: ' Нечего добавлять',
            });
            return;
        }
        try {
            store.dispatch({
                type: UPDATE_CATALOG_CART,
                payload: {filters: {[filterId]: productsData?.config}},
            })
            showSnack({
                variant: 'success',
                message: `${productsData?.config.count} товаров добавлено в выгрузку`,
            })
        } catch (e) {
            showSnack({
                variant: 'error',
                message: e.message,
            });
        }
    }
    if (providerId && !provider.id) {
        return null;
    }

    return (
        <>
            <div style={catalogCategoryProductsStyles.cardContainer}>

                <Grid item md={3} style={catalogCategoryProductsStyles.gridItem }>
                    <Card style={catalogCategoryProductsStyles.cardProductList }>
                        <CardMedia
                            component="img"
                            height="120"
                            // image={provider.image}
                            image='https://wordpress.dropshipping.ua/wp-content/uploads/2023/02/products.webp'
                            alt="image"
                        />
                        <CardContent style={catalogCategoryProductsStyles.cardContent}>
                            <Typography component="div" style={catalogCategoryProductsStyles.listItemText}>
                                {provider.name ? provider.name : 'Все товары'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={3} style={catalogCategoryProductsStyles.centerGridItem }>
                    <List >
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <WorkOutlineOutlined/>
                            </ListItemIcon>
                            <ListItemText style={catalogCategoryProductsStyles.listItemText}
                                primaryTypographyProps={{ style: { fontSize: 12, lineHeight: 1 } }}
                                primary="Товаров" />
                            <span style={catalogCategoryProductsStyles.spanProducts}>{productsData?.statistics?.products}</span>
                        </ListItem>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <LocalOfferOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText style={catalogCategoryProductsStyles.listItemText}
                                primaryTypographyProps={{ style: { fontSize: 12, lineHeight: 1 } }}
                                primary="Брендов" />
                            <span style={catalogCategoryProductsStyles.spanProducts}>{productsData?.statistics?.vendors}</span>
                        </ListItem>
                        {
                            !isSupplier &&
                            <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <StorefrontOutlined/>
                            </ListItemIcon>
                            <ListItemText style={catalogCategoryProductsStyles.listItemText}
                                primaryTypographyProps={{ style: { fontSize: 12, lineHeight: 1 } }}
                                primary="Поставщиков" />
                            <span style={catalogCategoryProductsStyles.spanProducts}>{productsData?.statistics?.providers}</span>
                        </ListItem>}
                    </List>
                    {!isSupplier &&
                    <Tooltip
                        title={isInCatalogCart ? "Удаляйте товары из выгрузки" : "Добавляйте выбранные товары в выгрузку"}
                        arrow
                        placement="right"
                    >
                    
                        <Button
                            onClick={addToCatalogCart}
                            variant='contained'
                            color='primary'
                            style={catalogCategoryProductsStyles.exportButton}>
                        {isInCatalogCart ? 'Удалить из выгрузки' : 'Добавить в выгрузку'}
                    </Button>
                    </Tooltip>
                    }
                </Grid>
                <Grid item md={5} style={catalogCategoryProductsStyles.centerGridItem }>
                    <List>
                        {!isSupplier ? (
                        <>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <FilterListOutlinedIcon/>
                            </ListItemIcon>
                            <Typography
                                color='textSecondary'
                                variant='caption'
                            >
                                {"Фильтруйте товары в таблице, чтобы добавить в XML-выгрузку только выбранные категории или бренды для размещения у вас на сайте"}
                                </Typography>
                        </ListItem>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <CloudUploadOutlinedIcon/>
                            </ListItemIcon>
                            <Typography
                                color='textSecondary'
                                variant='caption'
                            >
                                {"Создать XML-выгрузку с товарами конкретного поставщика также можно в разделе "}
                                <Link
                                        to={providerListPath}
                                        style={{ fontSize: 12, color: '#2792E0', textDecoration: 'underline', display: 'inline-block' }}
                                    >
                                        Поставщики
                                    </Link>
                                </Typography>
                        </ListItem>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <LocalMallOutlined/>
                            </ListItemIcon>
                            <Typography
                                color='textSecondary'
                                variant='caption'
                            >
                                {"Создавайте до 3-х XML-выгрузок для разных торговых площадок. Все созданные вами выгрузки будут доступны в разделе "}
                                <Link
                                        to={feedListPath}
                                        style={{ fontSize: 12, color: '#2792E0', textDecoration: 'underline', display: 'inline-block' }}
                                    >
                                        Мои XML выгрузки
                                    </Link>
                                </Typography>
                        </ListItem>
                    </>
                    ) : (
                    <>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <CloudDownloadOutlinedIcon/>
                            </ListItemIcon>
                            <Typography
                                color='textSecondary'
                                variant='caption'
                            >
                                {"Для размещения ваших товаров в каталоге платформы загружайте XML-файл в разделе "}
                                <Link
                                    to={productXmlImportPath}
                                    style={{ fontSize: 12, color: '#DB4C19', display: 'inline-block' }}
                                >
                                    Импорт товаров
                                </Link>
                            </Typography>
                        </ListItem>
                        <ListItem style={{ padding: '2px 12px' }}>
                            <ListItemIcon style={catalogCategoryProductsStyles.listItemIcon}>
                                <FilterListOutlinedIcon/>
                            </ListItemIcon>
                            <Typography
                                color='textSecondary'
                                variant='caption'
                            >
                                {"Дропшипперы могут фильтровать товары в таблице и добавлять на свой сайт только выбранные категории, бренды или товары конкретного поставщика"}
                            </Typography>
                        </ListItem>
                        </>
                        )}       
                    </List>
                </Grid>
            </div>
            <br/>
            <Products {...props} provider={provider} />
        </>
    );
}