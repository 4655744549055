import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
// import IconAdd from '@material-ui/icons/Add'
import TelegramIcon from '@material-ui/icons/Telegram';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import classNames from 'classnames'

import {
    catalogViewPath,
    newOrderPath, productsPath,
} from 'config/routes'
import {
    checkAlloved, checkNewUserProfileFilled,
} from 'config/roles'

import { menuConfig, RouterListener } from './menuConfig'
import {Collapse} from "@material-ui/core";
import {getCategoriesByLevel} from "../../../services/api/category.service";
import _ from "lodash";


const Menu = (props) => {
    const [openItems, setOpenItems] = useState({});
    const [categoryMenuItems, setCategoryMenuItems] = useState([]);
    useEffect(() => {
        if (categoryMenuItems.length) {
            return;
        }
        getCategoriesByLevel(0).then((data) => {
            setCategoryMenuItems(data.categories.map(item => {
                return {
                    idx: item.id,
                    name: item.name,
                    path: catalogViewPath.replace(':id', item.id),
                }
            }));
        }).catch((err) => {
            console.log(err)
        })
    }, []);
    const handleClick = (id) => {
        setOpenItems(prevOpenItems => ({
            ...prevOpenItems,
            [id]: !prevOpenItems[id]
        }))
    }

    const handleItemClick = (item) => {
        if (item.subMenuItems) {
            handleClick(item.idx)
        } else {
            props.history.push(item.path)
        }
    }

    const handleIconClick = (e, item) => {
        e.stopPropagation()
        handleClick(item.idx)
    }

    const {
        classes,
        history,
        menuItem,
    } = props;

    const dividerIndex = menuConfig.length - 5

    return (
        <div className={classes.root}>
            <div className={classes.toolbar}>
                <ListItem
                    button
                    selected={menuItem === 4}
                    onClick={() => { window.open('https://t.me/ceodropshippingua', '_blank') }}
                    classes={{
                        selected: classes.focus,
                        default: classes.defaultItem,
                        container: classes.listButton,
                    }}
                >
                    <ListItemIcon className={classNames(classes.menuIcon, classes.topIcon)}>
                        <TelegramIcon fontSize='medium' />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            color: 'inherit',
                            variant: 'body1',
                            style: { fontWeight: 400 },
                        }}
                        primary='ЗАДАТЬ ВОПРОС'
                    />
                </ListItem>
            </div>
            <Divider />
            <List>
                {
                    menuConfig
                        .filter(item => checkAlloved({ allowedRoles: item.allowedRoles }))
                        .map((item, index) => {
                            const listItem = (
                                <ListItem
                                    key={item.idx}
                                    button
                                    selected={menuItem === item.idx}
                                    onClick={() => handleItemClick(item)}
                                    classes={{
                                        selected: classes.focus,
                                        default: item.idx === 0 && classes.defaultItem,
                                    }}
                                >
                                    <ListItemIcon className={classes.menuIcon}>
                                        {item.icon()}
                                    </ListItemIcon>
                                    <ListItemText
                                        primaryTypographyProps={{
                                            color: 'inherit',
                                            style: { fontSize: 14, fontWeight: '500' },
                                        }}
                                        primary={item.primary}
                                    />
                                    {item.subMenuItems ? (
                                        openItems[item.idx] ? 
                                        <ExpandLessIcon onClick={(e) => handleIconClick(e, item)} /> : 
                                        <ExpandMoreIcon onClick={(e) => handleIconClick(e, item)} />
                                    ) : null}
                                    {item.endIcon && !checkNewUserProfileFilled() ? item.endIcon : ''}
                                </ListItem>
                            )
                            if (index === dividerIndex) {
                                return (
                                    <React.Fragment key={item.idx}>
                                        <Divider style={{ height: '20px' }}/>
                                        {listItem}
                                    </React.Fragment>
                                )
                            }

                            if (item.subMenuItems) {
                                if (item.subMenuItems.length) {
                                    return [
                                        listItem,
                                        <Collapse key={item.idx * 10} in={openItems[item.idx]} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {
                                                    item.subMenuItems.map((subMenuItem => (
                                                        <ListItem
                                                            key={subMenuItem.idx * 100}
                                                            sx={{ pl: 4 }}
                                                            button
                                                            onClick={() => history.push(subMenuItem.path)}
                                                        >
                                                            <ListItemIcon />
                                                            <ListItemText
                                                                primaryTypographyProps={{
                                                                    color: 'inherit',
                                                                    style: { fontSize: 14, whiteSpace: 'normal' },
                                                                }}
                                                                primary={subMenuItem.name}
                                                            />
                                                        </ListItem>
                                                    )))
                                                }
                                            </List>
                                        </Collapse>
                                    ]
                                }
                                return [
                                    listItem,
                                    <Collapse key={item.idx * 10} in={openItems[item.idx]} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem
                                                key={item.idx * 50}
                                                sx={{ pl: 4 }}
                                                button
                                                onClick={() => history.push(productsPath)}
                                            >
                                                <ListItemIcon />
                                                <ListItemText style={{ backgroundColor: '#2792E0', borderRadius: '10px', textAlign: 'center' }} primary="Все товары" />
                                            </ListItem>
                                            {
                                                categoryMenuItems.map((subMenuItem => (
                                                    <ListItem
                                                        key={subMenuItem.idx * 100}
                                                        sx={{ pl: 4 }}
                                                        button
                                                        onClick={() => history.push(subMenuItem.path)}
                                                    >
                                                        <ListItemIcon />
                                                        <ListItemText
                                                            primaryTypographyProps={{
                                                                color: 'inherit',
                                                                style: { fontSize: 14 },
                                                            }}
                                                            primary={_.truncate(subMenuItem.name, { 'length': 18, 'omission': '...' })} />
                                                    </ListItem>
                                                )))
                                            }
                                        </List>
                                    </Collapse>
                                ]
                            }

                            return listItem;
                        })
                }
            </List>
            <RouterListener />
        </div>
    )
}

Menu.defaultProps = {
    menuItem: 4,
}

Menu.propTypes = {
    menuItem: PropTypes.number,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
}

export default Menu
